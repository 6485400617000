import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";

const PREFIX = "ForgotPasswordComponent";

const classes = {
  form: `${PREFIX}-form`,
  inputWrapper: `${PREFIX}-inputWrapper`,
  submit: `${PREFIX}-submit`,
  link: `${PREFIX}-link`
};

const Root = styled("div")({
  [`& .${classes.form}`]: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 auto",
    width: "100%"
  },
  [`& .${classes.inputWrapper}`]: {
    width: "100%",
    marginBottom: "15px"
  },
  [`& .${classes.submit}`]: {
    marginTop: "15px"
  },
  [`& .${classes.link}`]: {
    textAlign: "right",
    width: "100%"
  }
});

const constructErrorMessage = exception => {
  switch (exception.code) {
    case "LimitExceededException":
      return exception.message;
    case "UserNotFoundException":
      return "Could not find username, please try again.";
    default:
      return "An error occured.";
  }
};

const ForgotPasswordComponent = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [user, setUser] = useState("");

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      await Auth.forgotPassword(user);
      enqueueSnackbar("Please check your email for a confirmation code.");
    } catch (err) {
      enqueueSnackbar(constructErrorMessage(err), { variant: "error" });
    }
  };
  return (
    <Root>
      <div className={classes.inputWrapper}>
        <h3>Reset your password</h3>
      </div>
      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.inputWrapper}>
          <TextField
            id="username"
            label="Username"
            type="username"
            name="username"
            fullWidth
            value={user}
            onChange={e => setUser(e.target.value)}
          />
        </div>
        <Link to={"/auth/login"} className={classes.link}>
          Back to Login
        </Link>
        <Button
          color="primary"
          fullWidth
          variant="contained"
          className={classes.submit}
          type="submit"
        >
          Send Code
        </Button>
      </form>
    </Root>
  );
};

export default ForgotPasswordComponent;
