import "./polyfill";
import "es6-shim";
import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { currentSession } from "./services/Auth";

import DateFnsUtils from "@date-io/date-fns";
import { SnackbarProvider } from "notistack";

// disable ServiceWorker
// import registerServiceWorker from './registerServiceWorker';
import { gqlBaseUrl, ENV } from "./config";

import {
  ApolloClient,
  ApolloLink,
  Observable,
  HttpLink,
  ApolloProvider
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { InMemoryCache } from "@apollo/client/cache";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const isDevMode = ENV === "dev" || ENV === "local";

// `merge: false` specifies the default behavior to silence warnings
// - incoming cache updates will replace the previous cache data
// keyFields directs apollo to use a different field as the UUID for that object
const cache = new InMemoryCache({
  typePolicies: {
    AlternativeStrategy: {
      fields: {
        alternativeStrategyProducts: {
          merge: false
        },
        shortages: {
          merge: false
        }
      }
    },
    CampusInventory: {
      keyFields: ["campus", "drug"]
    },
    LocationInventory: {
      keyFields: ["drug", "location"]
    },
    DispenserInventory: {
      keyFields: ["drug", "dispenser"]
    },
    DispenserGroup: {
      fields: {
        dispensers: {
          merge: false
        }
      }
    },
    ExtCommFieldAndNote: {
      keyFields: ["fieldId", "shortageId"]
    },
    OrderNote: {
      // disables object normalization
      // makes it default to the parent field, `Order`
      // desirable here because an OrderNote might not exist, therefore no good alternative UUID
      keyFields: false
    },
    Product: {
      fields: {
        orders: {
          merge: false
        },
        activeShortageListing: {
          merge: false
        }
      }
    },
    PurchaseRate: {
      keyFields: ["productID"]
    },
    Shortage: {
      fields: {
        labels: {
          merge: false
        },
        alternativeStrategies: {
          merge: false
        }
      }
    },
    ShortageDigest: {
      fields: {
        labels: {
          merge: false
        },
        dataFields: {
          merge: false
        },
        extCommFields: {
          merge: false
        }
      }
    },
    ShortageTask: {
      fields: {
        followUpTasks: {
          merge: false
        }
      }
    },
    Query: {
      fields: {
        allShortages: {
          merge: false
        },
        allShortageTasks: {
          merge: false
        },
        allShortageTasksByShortage: {
          merge: false
        },
        getAllExtCommFields: {
          merge: false
        },
        getLabelsSortedByShortageListingAssociationDate: {
          merge: false
        },
        getManualInventory: {
          merge: false
        },
        getUsers: {
          merge: false
        },
        getUserSso: {
          merge: false
        },
        listLabels: {
          merge: false
        }
      }
    }
  }
});

const request = async operation => {
  const session = await currentSession();
  const existingContext = operation.getContext();
  operation.setContext({
    headers: {
      Authorization: session ? `${session.idToken.jwtToken}` : "",
      ...existingContext.headers
    }
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    requestLink,
    new HttpLink({
      uri: gqlBaseUrl(),
      credentials: "same-origin"
    })
  ]),
  connectToDevTools: isDevMode
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
          <App />
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
// disable ServiceWorker
// registerServiceWorker();
