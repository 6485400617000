import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Hub } from "aws-amplify";

import Button from "@mui/material/Button";

import logo from "../../assets/img/brand/OrbitalRX-logo-h-fc-flat-rgb.png";
import { useSnackbar } from "notistack";
import { SSO_LOGIN } from "../../config";

const PREFIX = "SSOLogin";

const classes = {
  container: `${PREFIX}-container`,
  form: `${PREFIX}-form`,
  logo: `${PREFIX}-logo`,
  signIn: `${PREFIX}-signIn`,
  textField: `${PREFIX}-textField`,
  inputWrapper: `${PREFIX}-inputWrapper`
};

const Root = styled("div")({
  [`&.${classes.container}`]: {
    display: "flex",
    flexWrap: "wrap"
  },
  [`& .${classes.form}`]: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 auto",
    width: "100%"
  },
  [`& .${classes.logo}`]: {
    maxHeight: "90px",
    margin: "35px auto 65px auto"
  },
  [`& .${classes.signIn}`]: {
    textAlign: "center",
    color: "#404041"
  },
  [`& .${classes.textField}`]: {
    width: "100%"
  },
  [`& .${classes.inputWrapper}`]: {
    width: "100%",
    marginBottom: "15px"
  }
});

const SSOLogin = () => {
  const redirect = SSO_LOGIN;
  const { enqueueSnackbar } = useSnackbar();

  const handleSSOLogin = e => {
    e.preventDefault();
    try {
      window.location.assign(redirect);
    } catch (err) {
      console.log(err);
      enqueueSnackbar(`Error signing in with SSO`, {
        variant: "error"
      });
    }
  };
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Error", data);
          break;
      }
    });
  }, []);
  return (
    <Root className={classes.container}>
      <img alt="OrbitalRX Logo" src={logo} className={classes.logo} />
      <form className={classes.form} onSubmit={handleSSOLogin}>
        <Button
          color="primary"
          variant="contained"
          style={{ width: "100%", marginTop: "15px" }}
          type="submit"
        >
          SIGN IN
        </Button>
      </form>
    </Root>
  );
};

export default SSOLogin;
