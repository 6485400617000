import React, { useEffect, useState } from "react";

import classNames from "classnames";
import { Routes, secondaryMobileRoutes } from "./routes";
import { canRenderAdminPages, signOut } from "../../services/Auth";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Notifications from "../../components/Notifications";
import SupportLink from "../../components/SupportLink";
import CurrentUser from "../../components/CurrentUser";
import { CircularProgress } from "@mui/material";
import logo from "../../assets/img/brand/white@2x.png";
import * as Sentry from "@sentry/browser";
import {
  useGetCurrentUserQuery,
  useGetFirstOrganizationHeaderQuery
} from "../../generated/graphql";
import { useHistory } from "react-router-dom";

// Material
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import useScreenSize from "./useScreenSize";

const PREFIX = "NavDrawer";
const drawerWidth = "250px";
const closedDrawerWidth = theme => `calc(${theme.spacing(7)} + 1px)`;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`
  }
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const classes = {
  grow: `${PREFIX}-grow`,
  navButtons: `${PREFIX}-navButtons`,
  user: `${PREFIX}-user`,
  activeIcon: `${PREFIX}-activeIcon`,
  inactiveIcon: `${PREFIX}-inactiveIcon`,
  nestedIcon: `${PREFIX}-nestedIcon`,
  nestedIconItem: `${PREFIX}-nestedIconItem`,
  selectedListItem: `${PREFIX}-selectedListItem`,
  selected: `${PREFIX}-selected`,
  link: `${PREFIX}-link`,
  invisibleLink: `${PREFIX}-invisibleLink`,
  linkContain: `${PREFIX}-linkContain`
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.grow}`]: {
    flexGrow: 1
  },
  [`& .${classes.navButtons}`]: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  [`& .${classes.user}`]: {
    alignSelf: "center",
    paddingRight: "2em"
  },
  [`& .${classes.activeIcon}`]: {
    color: theme.palette.secondary.main
  },
  [`& .${classes.inactiveIcon}`]: {
    color: theme.palette.primary.light
  },
  [`& .${classes.selectedListItem}`]: {
    backgroundColor: "#F2F6F8 !important"
  },
  [`& .${classes.selected}`]: {
    fontWeight: "bold"
  },
  [`& .${classes.link}`]: {
    color: "black"
  },
  [`& .${classes.invisibleLink}`]: {
    visibility: "hidden"
  },
  [`& .${classes.linkContain}`]: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15
  }
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== "open"
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme)
      }
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme)
      }
    }
  ]
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== "open"
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
      }
    }
  ],
  position: "fixed"
}));

const OrxMenu = ({
  showAdminOptions,
  anchorEl,
  isMenuOpen,
  handleMenuClose,
  navigateToAccountSettings,
  navigateToAdmin,
  signOut
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
          navigateToAccountSettings();
        }}
      >
        Account Settings
      </MenuItem>
      {showAdminOptions && (
        <MenuItem
          onClick={() => {
            handleMenuClose();
            navigateToAdmin();
          }}
        >
          Admin Panel
        </MenuItem>
      )}
      <MenuItem onClick={signOut}>Logout</MenuItem>
    </Menu>
  );
};
export const MiniDrawer = ({ path }) => {
  const theme = useTheme();
  const screenSize = useScreenSize();
  const [open, setOpen] = useState(false);

  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const date = new Date();

  const {
    data: userData,
    loading: userLoading,
    error: userError
  } = useGetCurrentUserQuery();
  const {
    data: orgData,
    loading: orgLoading,
    error: orgError
  } = useGetFirstOrganizationHeaderQuery();

  if (userLoading || orgLoading) return <CircularProgress />;
  if (orgError) {
    Sentry.captureMessage(
      `Failed to load first org from GraphQL. Error: ${JSON.stringify(orgError)}`
    );
  }
  if (userError) {
    Sentry.captureMessage(
      `Failed to load current user from GraphQL. Error: ${JSON.stringify(userError)}`
    );
  }

  if (!userData) {
    Sentry.captureMessage(`Failed to load current user data from GraphQL.`);
  }

  const { getFirstOrganization: org } = orgData;
  const { getCurrentUser: user } = userData;

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const navigateToAdmin = () => {
    history.push("/admin");
  };

  const navigateToAccountSettings = () => {
    history.push("/settings");
  };
  const isMenuOpen = Boolean(anchorEl);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  const appTenant = process.env.REACT_APP_TENANT;
  const appEnvironment =
    process.env.REACT_APP_ENVIRONMENT === "production"
      ? null
      : process.env.REACT_APP_ENVIRONMENT;

  return (
    <Box
      sx={[
        open && { marginLeft: drawerWidth },
        !open && { marginLeft: closedDrawerWidth(theme) }
      ]}
    >
      <AppBar>
        <Toolbar>
          <Drawer variant="permanent" open={open}>
            <DrawerHeader sx={[open && { justifyContent: "flex-end" }]}>
              <IconButton
                color={"default"}
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={[open && { display: "none" }, { marginLeft: 0 }]}
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="close drawer"
                onClick={handleDrawerClose}
                edge="start"
                sx={[!open && { display: "none" }]}
              >
                <ChevronLeftIcon />
              </IconButton>
            </DrawerHeader>
            <Divider />

            <List component="nav">
              {/* TODO: add admin, account settings to secondary routes? since the menu disappears with smaller screen */}
              {Routes.map((route, index) => (
                <React.Fragment key={`nav-item-${index}`}>
                  <Tooltip title={!open ? `${route.viewValue}` : ""}>
                    <ListItemButton
                      component="a"
                      href={`#${route.route}`}
                      key={route.value}
                      selected={path.includes(route.route)}
                      classes={{ selected: classes.selectedListItem }}
                      sx={{ justifyContent: "flex-start !important" }}
                    >
                      <ListItemIcon
                        className={classNames({
                          [classes.activeIcon]: path.includes(route.route),
                          [classes.inactiveIcon]: !path.includes(route.route)
                        })}
                        sx={{ paddingLeft: 0.5 }}
                      >
                        {route.icon}
                      </ListItemIcon>
                      <ListItemText
                        classes={{
                          primary: path.includes(route.route)
                            ? classes.selected
                            : null
                        }}
                        primary={route.viewValue}
                      />
                    </ListItemButton>
                  </Tooltip>
                </React.Fragment>
              ))}
            </List>
            {screenSize.width < 900 && (
              <div>
                <Divider />
                <List>
                  {secondaryMobileRoutes.map(route => (
                    <ListItemButton
                      component="a"
                      href={`#${route.route}`}
                      key={route.value}
                      sx={{ marginLeft: 0.5 }}
                      classes={{
                        selected: classes.selectedListItem
                      }}
                    >
                      <ListItemIcon>{route.icon}</ListItemIcon>
                      <ListItemText primary={route.viewValue} />
                    </ListItemButton>
                  ))}
                </List>
              </div>
            )}
            <div
              className={classNames({
                [classes.invisibleLink]: !open
              })}
              style={{
                position: "absolute",
                bottom: 25,
                marginLeft: "20%"
              }}
            >
              <span id="logo" className={classes.linkContain}>
                <a
                  className={classes.link}
                  href="https://orbitalrx.com"
                  target="_blank"
                  rel="noopener"
                >
                  OrbitalRX
                </a>{" "}
                &copy; {date.getFullYear()}.
              </span>
            </div>
          </Drawer>

          <div style={{ marginRight: 25, marginLeft: "1%" }}>
            <img src={logo} height={40} />
          </div>
          {appEnvironment && (
            <Typography variant="h6" color="inherit" className={classes.grow}>
              {`APP ENVIRONMENT: ${appTenant} - ${appEnvironment.toUpperCase()}`}
            </Typography>
          )}
          <div className={classes.grow} />
          <div className={classes.navButtons}>
            <Notifications />
            {org.id && (
              <SupportLink
                isMenuOpen={isMenuOpen}
                isMDXCustomer={org.supportedByMDX}
              />
            )}
            <IconButton
              aria-owns={isMenuOpen ? "material-appbar" : undefined}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              style={{ padding: 3, marginRight: 7 }}
            >
              <AccountCircle />
            </IconButton>
            <Typography className={classes.user}>
              <CurrentUser />
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <OrxMenu
        showAdminOptions={canRenderAdminPages(user.authorized_pages)}
        anchorEl={anchorEl}
        isMenuOpen={isMenuOpen}
        handleMenuClose={handleMenuClose}
        navigateToAccountSettings={navigateToAccountSettings}
        navigateToAdmin={navigateToAdmin}
        signOut={signOut}
      />
    </Box>
  );
};

// base navigation menu
const DefaultNav = ({ path }) => {
  const theme = useTheme();
  return (
    <Root theme={theme}>
      <MiniDrawer path={path} />
    </Root>
  );
};

export default DefaultNav;
