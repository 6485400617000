import React, { ReactElement } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";

interface StyleProps {
  fontSize?: string | null;
  disableunderline?: boolean;
  fontcolor?: string | null;
  fontWeight?: string | null;
}
interface LinkButtonProps extends StyleProps {
  text?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
}

export const StyledButton = styled(Button)<StyleProps>`
  font-size: ${props => props.fontSize || "14px"};
  color: ${props => props.fontcolor || "#20a8d8"};
  cursor: pointer;
  text-decoration: ${props => (props.disableunderline ? "none" : "underline")};
  text-transform: none;
  font-weight: ${props => props.fontWeight || "400"};
  margin: 0 -8px;
  display: contents;
  align-items: center;
  width: 100%;
  &:hover {
    text-decoration: underline;
    background-color: transparent;
  }
`;

const LinkButton = ({
  text,
  onClick,
  fontSize,
  disableunderline,
  children,
  fontcolor,
  fontWeight
}: LinkButtonProps): ReactElement => {
  return (
    <StyledButton
      disableRipple={true}
      onClick={onClick}
      fontSize={fontSize}
      fontcolor={fontcolor}
      disableunderline={disableunderline}
      fontWeight={fontWeight}
    >
      {children || text}
    </StyledButton>
  );
};

export default LinkButton;
