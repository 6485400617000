import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Switch, Route, Redirect } from "react-router-dom";

import LoginComponent from "./Login";
import SSOLogin from "./SSOLogin";
import ForgotPasswordComponent from "./ForgotPassword";
import ConfirmForgotPasswordComponent from "./ConfirmForgotPassword";
import ConfirmSignupComponent from "./ConfirmSignup";
import { isSSO } from "../../config";

import Card from "@mui/material/Card";

const PREFIX = "Authenticator";

const classes = {
  marginTop: `${PREFIX}-marginTop`,
  container: `${PREFIX}-container`
};

const Root = styled("div")({
  [`&.${classes.marginTop}`]: {
    marginTop: "5%"
  },
  [`& .${classes.container}`]: {
    width: "33%",
    padding: 25,
    height: "auto",
    margin: "0 auto",
    backgroundColor: "#fff",
    borderRadius: "7px"
  }
});

const Authenticator = () => {
  return (
    <Root className={classes.marginTop}>
      <Card className={classes.container}>
        <Switch>
          <Route path="/auth/login" exact>
            {isSSO && <SSOLogin />}
            {!isSSO && <LoginComponent />}
          </Route>
          <Route path="/auth/orbitalrxlogin">
            {/* orbitalrx specific login page user/pw */}
            {isSSO && <LoginComponent />}
          </Route>
          <Route path="/auth/password-reset" exact>
            <ForgotPasswordComponent />
          </Route>
          <Route path="/auth/confirm-password-reset" exact>
            <ConfirmForgotPasswordComponent />
          </Route>
          <Route path="/auth/confirm-signup" exact>
            <ConfirmSignupComponent />
          </Route>
          <Route path="/auth" render={() => <Redirect to="/auth/login" />} />
        </Switch>
      </Card>
    </Root>
  );
};

export default Authenticator;
