import React from "react";
import MailIcon from "@mui/icons-material/Mail";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PollIcon from "@mui/icons-material/Poll";
import ListIcon from "@mui/icons-material/List";
import ExitToApp from "@mui/icons-material/ExitToApp";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";

export const Routes = [
  {
    value: "pharmacy",
    route: "shortages",
    viewValue: "Shortages",
    sub: {
      hasSubNav: false
    },
    icon: <LocalPharmacyIcon />,
    badge: {
      hasBadge: false
    }
  },
  {
    value: "tasks",
    route: "tasks",
    viewValue: "Tasks",
    sub: {
      hasSubNav: false
    },
    icon: <AssignmentIcon />,
    badge: {
      hasBadge: false
    }
  },
  {
    value: "reporting",
    route: "reports",
    viewValue: "Reporting",
    sub: {
      hasSubNav: false
    },
    icon: <PollIcon />,
    badge: {
      hasBadge: false
    }
  },
  {
    value: "inventory",
    route: "inventory/manage",
    viewValue: "Manual Inventory",
    sub: {
      hasSubNav: false
    },
    icon: <ListIcon />,
    badge: {
      hasBadge: false
    }
  },
  {
    value: "altFinder",
    route: "altfinder",
    viewValue: "Alternatives Finder",
    sub: {
      hasSubNav: false
    },
    icon: <EmojiObjectsIcon />,
    badge: {
      hasBadge: false
    }
  }
];

export const secondaryMobileRoutes = [
  {
    value: "logout",
    route: "auth",
    viewValue: "Logout",
    sub: {
      hasSubNav: false
    },
    icon: <ExitToApp />,
    badge: {
      hasBadge: false
    }
  }
];
