import React from "react";
import Divider from "@mui/material/Divider";
import dayjs from "dayjs";
import Notification from "./Notification";
import styled from "styled-components";

const NotificationsList = ({ notifications, isError }) =>
  notifications.length === 0 ? (
    <EmptyList isError={isError} />
  ) : (
    [...notifications].sort(compareNotifications).reduce((a, b) => {
      if (a === null) {
        if (
          b.__typename === "UsageNotification" ||
          b.__typename === "ExcessiveUsageNotification"
        ) {
          if (!b.shortageListing) return null;
        }
        return [<Notification key={b.__typename + b.id} notification={b} />];
      } else {
        if (
          b.__typename === "UsageNotification" ||
          b.__typename === "ExcessiveUsageNotification"
        ) {
          if (!b.shortageListing) return a;
        }
        return a.concat([
          <Divider key={b.__typename + b.id + "divider"} />,
          <Notification key={b.__typename + b.id} notification={b} />
        ]);
      }
    }, null)
  );

export default NotificationsList;

const compareNotifications = (a, b) => {
  if (a.read !== b.read) return a.read ? 1 : -1;
  if (a.created_date === null || b.created_date === null) {
    if (a.created_date === b.created_date) return 0;
    return a.created_date === null ? 1 : -1;
  }
  return dayjs(b.created_date).diff(dayjs(a.created_date));
};

const EmptyList = ({ isError }) =>
  isError ? (
    <StyledEmptyList>
      We&apos;re having trouble fetching your notifications. The OrbitalRX team
      has been notified and is looking into the issue.
    </StyledEmptyList>
  ) : (
    <StyledEmptyList>You currently have no notifications.</StyledEmptyList>
  );
const StyledEmptyList = styled.div`
  width: 100%;
  height: 100%;
  padding: 1em;
  box-sizing: border-box;
`;
