import React from "react";
import { styled } from "@mui/material/styles";
import { Auth } from "aws-amplify";
import { withRouter, Link } from "react-router-dom";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Common from "../../utility/Common";

import { withSnackbar } from "notistack";
import logo from "../../assets/img/brand/OrbitalRX-logo-h-fc-flat-rgb.png";

const PREFIX = "Login";

const classes = {
  container: `${PREFIX}-container`,
  form: `${PREFIX}-form`,
  logo: `${PREFIX}-logo`,
  signIn: `${PREFIX}-signIn`,
  textField: `${PREFIX}-textField`,
  inputWrapper: `${PREFIX}-inputWrapper`
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.container}`]: {
    display: "flex",
    flexWrap: "wrap"
  },

  [`& .${classes.form}`]: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0 auto",
    width: "100%"
  },

  [`& .${classes.logo}`]: {
    maxHeight: "90px",
    margin: "35px auto 65px auto"
  },

  [`& .${classes.signIn}`]: {
    textAlign: "center",
    color: "#404041"
  },

  [`& .${classes.textField}`]: {
    width: "100%"
  },

  [`& .${classes.inputWrapper}`]: {
    width: "100%",
    marginBottom: "15px"
  }
}));

const internalUsers = [
  "adam@orbitalrx.com",
  "akilburn",
  "justinwolgamott@teambrightly.com",
  "jwolgamott-test",
  "mthanawala",
  "nate@orbitalrx.com",
  "sheltonjs"
];

class LoginComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      canDemo: false,
      isDemo: false
    };
  }

  _onSignIn = async e => {
    e.preventDefault();
    const { username, password, isDemo } = this.state;
    const { actions, history } = this.props;

    try {
      const user = await Auth.signIn(username.toLowerCase(), password);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        history.push({
          pathname: "/auth/confirm-signup",
          state: { user }
        });
        return;
      }

      if (isDemo && internalUsers.includes(username)) {
        sessionStorage.setItem("mode", JSON.stringify({ isDemo: true }));
      }

      let { from } = this.props.location.state || {
        from: { pathname: "/" }
      };
      history.replace(from);
    } catch (err) {
      if (err.code === "PasswordResetRequiredException") {
        history.push({
          pathname: "/auth/password-reset",
          state: { step: 2, username }
        });
      } else if (err.code === "LimitExceededException") {
        this.props.enqueueSnackbar(
          "Your account has been locked. Please contact support@orbitalrx.com for assistance.",
          { variant: "error" }
        );
      } else if (
        err.code === "NotAuthorizedException" ||
        err.code === "UserNotFoundException"
      ) {
        this.props.enqueueSnackbar(`${Common.messages.loginFailed}`, {
          variant: "error"
        });
      } else if (!err.message.includes("Pending")) {
        //disregards repeated click on login button
        this.props.enqueueSnackbar(`${Common.messages.error}`, {
          variant: "error"
        });
      }
    }
  };

  _checkUsername = username => {
    const canDemo = internalUsers.includes(username) ? true : false;
    this.setState({ canDemo, username });
  };

  render() {
    const {} = this.props;

    return (
      <Root className={classes.container}>
        <img alt="OrbitalRX Logo" src={logo} className={classes.logo} />
        <form className={classes.form} onSubmit={this._onSignIn}>
          <div className={classes.inputWrapper}>
            <h4 className={classes.signIn}> Sign in to your account </h4>
          </div>
          <div className={classes.inputWrapper}>
            <TextField
              id="username"
              label="Username"
              type="username"
              name="username"
              className={classes.textField}
              value={this.state.username}
              onChange={e => this._checkUsername(e.target.value)}
            />
          </div>
          <div className={classes.inputWrapper}>
            <TextField
              id="password"
              label="Password"
              type="password"
              name="password"
              className={classes.textField}
              value={this.state.password}
              onChange={e => this.setState({ password: e.target.value })}
            />
          </div>
          <Link
            style={{ textAlign: "right", width: "100%" }}
            to={"/auth/password-reset"}
          >
            Forgot Password?
          </Link>
          <Button
            color="primary"
            variant="contained"
            style={{ width: "100%", marginTop: "15px" }}
            type="submit"
            onClick={this._onSignIn}
          >
            SIGN IN
          </Button>
        </form>
      </Root>
    );
  }
}

export default withRouter(withSnackbar(LoginComponent));
