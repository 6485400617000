import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import NotificationsList from "./NotificationsList";
import styled from "styled-components";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";

const NotificationsPopover = ({
  notifications,
  anchorEl,
  handleClose,
  viewAll,
  markAllRead,
  isError
}) => {
  const doViewAll = () => {
    viewAll();
    handleClose();
  };

  const history = useHistory();
  const navigateToSettings = () => {
    history.push("/settings");
    handleClose();
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      data-testid="notifications-popover"
    >
      <Container>
        <Content>
          <Header>
            <Typography variant="caption" display="block">
              NOTIFICATIONS
            </Typography>
            <span>
              <a
                style={{ color: "#20a8d8", cursor: "pointer" }}
                onClick={markAllRead}
              >
                Mark all as read
              </a>
              <IconButton onClick={navigateToSettings} color="secondary">
                <SettingsIcon />
              </IconButton>
            </span>
          </Header>
          <ListContainer>
            <NotificationsList
              notifications={notifications}
              isError={isError}
            />
          </ListContainer>
        </Content>
        <Footer>
          <a
            style={{ color: "#20a8d8", cursor: "pointer" }}
            onClick={doViewAll}
          >
            View All Notifications
          </a>
        </Footer>
      </Container>
    </Popover>
  );
};
export default NotificationsPopover;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 0.5em;
  align-items: center;
  justify-content: space-between;
`;
const Container = styled.div`
  width: 50ch;
`;
const ListContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
`;
const Content = styled.div`
  padding-top: 1em;
  padding-right: 1em;
  padding-left: 1em;
  max-height: 28em;
  display: flex;
  flex-direction: column;
`;
const Footer = styled.div`
  background-color: #eeeeee;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 0.55em;
  padding-bottom: 0.55em;
`;
