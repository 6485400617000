import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { Auth } from "aws-amplify";

import { useLocation, useHistory } from "react-router-dom";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { useSnackbar } from "notistack";

import { CognitoUser } from "@aws-amplify/auth";

const PREFIX = "ConfirmSignupComponent";

const classes = {
  container: `${PREFIX}-container`,
  textField: `${PREFIX}-textField`,
  inputWrapper: `${PREFIX}-inputWrapper`
};

const Root = styled("form")({
  [`&.${classes.container}`]: {
    display: "flex",
    flexWrap: "wrap"
  },
  [`& .${classes.textField}`]: {
    width: "100%"
  },
  [`& .${classes.inputWrapper}`]: {
    width: "100%",
    marginBottom: "15px"
  }
});

export const ConfirmSignupComponent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const history = useHistory();

  const [user, setUser] = useState<CognitoUser | any>(
    location.state.user || ""
  );
  const [newPassword, setNewPassword] = useState<string>("");

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      await Auth.completeNewPassword(user, newPassword, {});
      history.push("/auth/login");
    } catch (err) {
      enqueueSnackbar(constructErrorMessage(err), {
        variant: "error",
        autoHideDuration: 5000
      });
    }
  };

  const constructErrorMessage = exception => {
    switch (exception.code) {
      case "LimitExceededException":
        return exception.message;
      case "InvalidPasswordException":
        return "New password fails constraints. Must be 8 characters long containing a number, character, and at least one uppercase letter.";
      case "NotAuthorizedException":
        history.push("/auth/login");
        return "Session has expired, please login with your username and provided temporary password.";
      default:
        return "An error occured.";
    }
  };

  return (
    <Root className={classes.container} onSubmit={handleSubmit}>
      <div className={classes.inputWrapper}>
        <h3>Reset your password</h3>
      </div>
      <div className={classes.inputWrapper}>
        <TextField
          id="password"
          name="password"
          label="New Password"
          type="password"
          className={classes.textField}
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
        />
      </div>
      <Button
        color="primary"
        variant="contained"
        disabled={!newPassword}
        style={{ width: "100%", marginTop: "15px" }}
        type="submit"
      >
        Reset Password
      </Button>
    </Root>
  );
};

export default ConfirmSignupComponent;
