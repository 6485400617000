import React from "react";

import MuiDrawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, CSSObject, styled, Theme, useTheme } from "@mui/material";

const drawerWidth = "40%";
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});
const Drawer = styled(MuiDrawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme)
      }
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme)
      }
    }
  ]
}));
interface OrxDrawerProps {
  isOpen: boolean;
  toggleDrawer: () => void;
  title?: string;
  children: any;
  keepMounted?: boolean;
}
const OrxDrawer = ({
  isOpen,
  toggleDrawer,
  title,
  children,
  keepMounted = false
}: OrxDrawerProps) => {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={toggleDrawer}
      data-testid="orx-drawer"
      keepMounted={keepMounted}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "64px",
          padding: "2%",
          background: "white",
          position: "sticky",
          top: 0,
          zIndex: 50,
          borderBottom: ".02rem solid #e2e2e2"
        }}
      >
        <Typography variant={"h5"}>{title}</Typography>
        <IconButton
          data-testid="orx-drawer-close-button"
          onClick={toggleDrawer}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {children}
    </Drawer>
  );
};

export default OrxDrawer;
