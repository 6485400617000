import React from "react";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

interface SupportLinkProps {
  isMenuOpen: boolean;
  isMDXCustomer: boolean;
}

const orxSupportLink =
  "https://orbitalrx.atlassian.net/servicedesk/customer/portals";
const mdxSupportLink =
  "https://orbitalrx.atlassian.net/servicedesk/customer/portals";
//todo check if new support link from merative

const mdxSupportName = `Merative Micromedex`;
const orxSupportName = "OrbitalRX";

const SupportLink = ({ isMenuOpen, isMDXCustomer }: SupportLinkProps) => {
  const link = isMDXCustomer ? mdxSupportLink : orxSupportLink;
  const supportName = isMDXCustomer ? mdxSupportName : orxSupportName;

  return (
    <Tooltip title={`Click to open the ${supportName} Support Portal`}>
      <IconButton
        aria-owns={isMenuOpen ? "material-appbar" : undefined}
        aria-haspopup="true"
        onClick={() => {
          window.open(link, "_blank");
        }}
        color="inherit"
        style={{ padding: 3 }}
      >
        <HelpIcon />
      </IconButton>
    </Tooltip>
  );
};

export default SupportLink;
