import React, { useState } from "react";
import CustomNotificationIcon from "../../assets/img/CustomNotificationIcon@2x.png";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsPopover from "./NotificationsPopover";
import NotificationsDrawer from "./NotificationsDrawer";
import {
  useCurrentUserNotificationsQuery,
  useSetAllNotificationReadStatusMutation
} from "../../generated/graphql";
import * as Sentry from "@sentry/browser";

const Notifications = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = event => setAnchorEl(event.currentTarget);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [hideAlertBadge, setHideAlertBadge] = useState(true);

  return (
    <div>
      <IconButton
        color="inherit"
        onClick={openPopover}
        data-testid="notifications-icon"
        style={{ padding: 3 }}
      >
        <Badge
          color="error"
          variant="dot"
          overlap="circular"
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          invisible={hideAlertBadge} // if no new notifications, invisible = true
          data-testid="notifications-read"
        >
          <img src={CustomNotificationIcon} style={{ height: 22, width: 22 }} />
        </Badge>
      </IconButton>
      <ListNotifications
        setHideAlertBadge={setHideAlertBadge}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        drawerIsOpen={drawerIsOpen}
        setDrawerIsOpen={setDrawerIsOpen}
      />
    </div>
  );
};

const ListNotifications = ({
  setHideAlertBadge,
  anchorEl,
  setAnchorEl,
  setDrawerIsOpen,
  drawerIsOpen
}) => {
  const { loading, error, data, refetch } = useCurrentUserNotificationsQuery({
    pollInterval: 300000,
    onCompleted: userdata => {
      if (userdata) {
        const { getCurrentUser: user } = userdata;
        const { notifications } = user;
        if (!!notifications.length) {
          setHideAlertBadge(false);
        }
      }
    }
  });
  const [setAllReadStatus] = useSetAllNotificationReadStatusMutation({
    onCompleted: () => {
      refetch();
      setHideAlertBadge(true);
    }
  });
  if (loading) return null;
  if (error) {
    Sentry.captureMessage(error);
  }

  let notifications = [];
  if (data) {
    const { getCurrentUser: user } = data;
    notifications = user.notifications;
  }

  const markAllRead = async () => {
    setAllReadStatus({
      variables: {
        read: true
      }
    });
  };

  return (
    <>
      <NotificationsPopover
        notifications={notifications}
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        viewAll={() => setDrawerIsOpen(true)}
        markAllRead={markAllRead}
        isError={!!error}
      />
      <NotificationsDrawer
        isOpen={drawerIsOpen}
        setIsOpen={setDrawerIsOpen}
        notifications={notifications}
        markAllRead={markAllRead}
        isError={!!error}
      />
    </>
  );
};

export default Notifications;
