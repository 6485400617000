import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Auth } from "aws-amplify";
import { useLocation, useHistory } from "react-router-dom";

import PasswordValidator from "../../services/PasswordValidator";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";

const PREFIX = "ConfirmForgotPasswordComponent";

const classes = {
  container: `${PREFIX}-container`,
  errorContainer: `${PREFIX}-errorContainer`,
  errorList: `${PREFIX}-errorList`,
  requirementMet: `${PREFIX}-requirementMet`,
  requirementUnmet: `${PREFIX}-requirementUnmet`
};

const Root = styled("div")({
  [`& .${classes.container}`]: {
    display: "flex",
    flexWrap: "wrap"
  },
  [`& .${classes.errorContainer}`]: {
    width: "100%",
    margin: "15px 0"
  },
  [`& .${classes.errorList}`]: {
    columns: "2",
    "-webkit-columns": "2",
    "-moz-columns": "2"
  },
  [`& .${classes.requirementMet}`]: {
    color: "black"
  },
  [`& .${classes.requirementUnmet}`]: {
    color: "#ababab"
  }
});

const constructErrorMessage = exception => {
  switch (exception.code) {
    case "LimitExceededException":
      return exception.message;
    case "CodeMismatchException":
      return exception.message;
    case "InvalidParameterException":
      return "New password fails constraints. Must be at least 8 characters long containing a number, special character, and uppercase letter.";
    default:
      return "An error occured.";
  }
};
const ConfirmForgotPasswordComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const query = new URLSearchParams(location.search);

  const username = query.get("username");
  const code = query.get("pwresettoken");

  // if username and code are not present, the user has arrived on this page by other means than
  // the password reset email.
  if (!username || !code) {
    history.push("/auth/login");
  }

  const [user, setUser] = useState(username);
  const [confirmationCode, setConfirmationCode] = useState(code);
  const [newPassword, setNewPassword] = useState("");

  const handleSubmitCode = async e => {
    e.preventDefault();

    const pw = new PasswordValidator(newPassword);

    if (!pw.valid()) {
      enqueueSnackbar("Your password does not meet requirements.", {
        variant: "error"
      });
      return;
    }

    try {
      await Auth.forgotPasswordSubmit(user, confirmationCode, newPassword);
      history.push("/auth/login");
      enqueueSnackbar("Successfully changed your password.");
    } catch (err) {
      enqueueSnackbar(constructErrorMessage(err), {
        variant: "error"
      });
    }
  };
  return (
    <Root>
      <div className={classes.inputWrapper}>
        <h3>Reset your password</h3>
      </div>
      <form className={classes.container} onSubmit={handleSubmitCode}>
        <TextField
          id="newPassword"
          label="New Password"
          type="password"
          name="newPassword"
          fullWidth
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
        />
        <PasswordRequirements password={newPassword} />
        <Button
          color="primary"
          variant="contained"
          fullWidth
          className={classes.submit}
          type="submit"
        >
          Reset Password
        </Button>
      </form>
    </Root>
  );
};

const PasswordRequirements = ({ password }) => {
  const validator = new PasswordValidator(password);

  const requirements = [
    {
      text: "One lowercase character",
      valid: validator.validateHasLowercase()
    },
    {
      text: "One uppercase character",
      valid: validator.validateHasUppercase()
    },
    { text: "One number", valid: validator.validateHasNumber() },
    {
      text: "One special character",
      valid: validator.validateHasSymbol()
    },
    {
      text: `${validator.minLength} characters minimum`,
      valid: validator.validateMinLength()
    }
  ];

  return (
    <div className={classes.errorContainer}>
      <ul className={classes.errorList}>
        {requirements.map(req => {
          return (
            <li
              key={req.text}
              className={
                req.valid ? classes.requirementMet : classes.requirementUnmet
              }
            >
              {req.text}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ConfirmForgotPasswordComponent;
