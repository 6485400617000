import styled from "styled-components";
import Avatar from "@mui/material/Avatar";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${props => (props.read ? "white" : "#F5FAFD")};
  padding: 1em;
  box-sizing: border-box;
  align-items: start;
`;

export const Contents = styled.div`
  flex-grow: 1;
  padding-left: 10px;
`;

export const StyledAvatar = styled(Avatar)`
  background-color: ${props => props.color};
  width: 22px;
  height: 22px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.87);
`;

export const MainContent = styled.div`
  flex-grow: 1;
  white-space: wrap;
`;

export const Footer = styled.div`
  color: gray;
`;
